body{

	background: #dadada;
	a{
		transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
		&:hover, &:focus{
			outline: none;
			text-decoration: none;
		}
	}
	p{
		margin-top: 5px;
	}

	img{
		max-width: 100%;
	}

	h1,h2,h3,h4{
		margin: 0;
	}

	@media (max-width: 991.98px){
		&.fixed-position {
			position: fixed;
			width: 100%;

			.wrapper{
				transform: translateX(-250px);
				transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					transition: 0s all cubic-bezier(0.075, 0.82, 0.165, 1);
				}
				
			}
		}
	}

	.black-jack{
		font-family: 'BlackJack'!important;
		font-weight: 400!important;
	}

	.font-bold{
		font-weight: bold;
	}

	.container {
		@media (min-width: 1200px){
			
				max-width: 1200px;
		}

		@media (max-width: 991.98px){
			
			max-width: 100%;
	}
		
	}

}



ul{
	margin:0;
}

.wrapper{
	max-width: 1229px;
	margin: 0 auto;
	background: #fff;
	box-shadow: 0 0 30px rgba(0,0,0,0.1);

}



