@import url(//fonts.googleapis.com/css?family=Droid+Sans:400,700);

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-Light.eot');
  src: url('../fonts/EuclidCircularB-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-Light.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-Light.woff') format('woff'),
      url('../fonts/EuclidCircularB-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-Italic.eot');
  src: url('../fonts/EuclidCircularB-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-Italic.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-Italic.woff') format('woff'),
      url('../fonts/EuclidCircularB-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-Bold.eot');
  src: url('../fonts/EuclidCircularB-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-Bold.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-Bold.woff') format('woff'),
      url('../fonts/EuclidCircularB-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-Regular.eot');
  src: url('../fonts/EuclidCircularB-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-Regular.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-Regular.woff') format('woff'),
      url('../fonts/EuclidCircularB-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-LightItalic.eot');
  src: url('../fonts/EuclidCircularB-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-LightItalic.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-LightItalic.woff') format('woff'),
      url('../fonts/EuclidCircularB-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-SemiBoldItalic.eot');
  src: url('../fonts/EuclidCircularB-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-SemiBoldItalic.woff') format('woff'),
      url('../fonts/EuclidCircularB-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-Medium.eot');
  src: url('../fonts/EuclidCircularB-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-Medium.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-Medium.woff') format('woff'),
      url('../fonts/EuclidCircularB-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-BoldItalic.eot');
  src: url('../fonts/EuclidCircularB-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-BoldItalic.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-BoldItalic.woff') format('woff'),
      url('../fonts/EuclidCircularB-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Euclid Circular B';
  src: url('../fonts/EuclidCircularB-SemiBold.eot');
  src: url('../fonts/EuclidCircularB-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/EuclidCircularB-SemiBold.woff2') format('woff2'),
      url('../fonts/EuclidCircularB-SemiBold.woff') format('woff'),
      url('../fonts/EuclidCircularB-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'BlackJack';
  src: url('../fonts/BlackJackRegular.eot');
  src: url('../fonts/BlackJackRegular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BlackJackRegular.woff2') format('woff2'),
      url('../fonts/BlackJackRegular.woff') format('woff'),
      url('../fonts/BlackJackRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


