.page-intro-area {
    padding: 30px 0;

    p {
        margin-bottom: 0;
    }

}

.pdf-btn {
    white-space: nowrap;
    margin-bottom: 15px;
    overflow: hidden;
    background-color: $lightblue;
    color: #fff;
    border: 5px solid #99ccff;
    line-height: 44px;
    text-indent: 62px;
    width: 100%;
    display: block;
    position: relative;
    transition: 0.2s all;
    font-weight: 700;

    &:hover {
        background-color: black;
        text-decoration: none;
        color: #fff;
    }

    span {
        background: #fff;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 44px;

        img {
            position: absolute;
            left: 4px;
            top: 3px;
        }
    }
}

.page-search-area {
    padding: 30px 0 20px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    background-color: #eee;
    font-size: 18px;

    h2 {
        font-size: 40px;
    }


    select {
        background: url('/image/drop-arrow.png') no-repeat right;
        background-color: #fff;
        appearance: none;
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */

    }
}

.property-options {
    select {
        border: 4px solid #cccccc;
        padding: 5px 8px;
        padding-right: 40px;
        font-weight: bold;
        margin-top: 15px;
        font-size: 16px;
    }

    i {
        font-size: 24px;
        color: #999999;
        margin: 0 10px 0 5px
    }

    button:hover {
        background: #333;
        border-color: #999;
    }

    .fa-bed,
    .fa-map-marker {
        position: relative;
        font-size: 40px;
        bottom: -8px;


    }

    .style-button {
        font-size: 18px;
        padding: 5px 20px;
    }


    @media(max-width: 767px) {
        font-size: 16px;

        .style-button {
            font-size: 16px;
        }
    }
}

.style-button {
    border: 4px solid #e0e0e0;
    font-size: 15px;
    margin-top: 15px;
    display: inline-block;
    padding: 7px 20px 6px;
    background-color: #fff;
    font-weight: bold;

  
    transition: all 0.5s ease-out;

}

i.pound-symbol {
    color: #fff !important;
    font-size: 16px;
    padding: 9px 13px;
    background-color: #999999;
  
    border-radius: 50px;
}

.sorting-area {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    label {
        font-size: 16px;
        font-weight: bold;
    }

    input[type=checkbox] {
        margin: 6px 0 0;
        margin-left: -20px;
    }

    .button {
        cursor: pointer;
        margin: 0 15px;
    }
}

.sorting-area.checkbox {
    margin-top: 15px;
}

.sorting-property-area {
    padding: 50px 0;
    border-bottom: 1px solid #ccc;
}

.single-sorting-property {
    margin: 0 0 20px;

    .sorting-property-price {
        .owl-item {
            img {
                width: 40px;
                margin-right: 5px;
                ;
            }
        }
    }
}

.sorting-property-img {
    background: #CCCCCC;
    padding: 5px;
    position: relative;

    cursor: pointer;

    &:hover {
        background: #666;
    }

    .product-image {
        height: 195px;
        background-size: cover;
        background-position: 50% 50%;
    }
}

.ribbon {

    position: absolute;
    top: 10px;
    right: 10px;
    background: #eee;
    text-transform: uppercase;
    padding: 5px;
    font-weight: bold;
    border-radius: 6px;
    font-size: 11px;

    .text-centered {
        line-height: 12px;
    }
}

.sorting-property-name {
    text-transform: uppercase;
    font-size: 14px;
    color: #585858;
    margin: 8px 0;
    font-weight: bold;
}

.price-slider {
    background-color: #ECECEC;
    line-height: 70px;
    height: 70px;
    margin-left: 0px;
    font-weight: bold;
    font-size: 14px;
    position: relative;

    i {
        background: #666666 none repeat scroll 0 0;
        border-radius: 50px;
        color: #fff;
        padding: 13px 12px;
        margin-right: 5px;
    }

    .owl-controls {
        position: absolute;
        top: 50%;
        width: 100%;
    }

    .owl-buttons {
        margin-top: -24px;
    }

    .owl-prev {
        background: rgba(0, 0, 0, 0) url("/image/res-arrow-left-2.png") no-repeat scroll left top;
    }

    .owl-next {
        background: rgba(0, 0, 0, 0) url("/image/res-arrow-right-2.png") no-repeat scroll right top;
    }
}

.price-bar {
    font-size: 21px;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    padding-right: 152px;
    background-color: #666666;
    color: #fff;
    position: relative;
}

.price-bar.no-pdf {
    font-size: 21px;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    //padding-right: 152px;
    background-color: #666666;
    color: #fff;
    position: relative;
    padding-right: 0;
    text-align: left;

    .search {
        right: 0;
    }

    .price,
    span {
        display: inline-block;
        text-align: center;
        width: calc(100% - 106px);
    }

    a {
        display: inline-block;
    }
}

.search,
.pdf {
    position: absolute;
    right: 46px;
    top: 0;
    width: 46px;
    height: 45px;
    border-left: 1px solid #fff;

    &:hover {
        background-color: #CC0033;
    }
}

.search {
    width: auto;
    font-size: 15px;
    color: #fff;
    padding: 0 8px;

    &:hover,
    &:focus {
        background: #333 !important;
        color: #fff;
        text-decoration: none;
    }

}

.pdf {
    right: 0px;
    background: url("/image/pdf.png") no-repeat 0 0 #333333;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-radius: 0;
}

.pagination>li>a,
.pagination>li>span {
    color: #666;

}

.red {
    .pagination>.active>a,
    .pagination>.active>a:focus,
    .pagination>.active>a:hover,
    .pagination>.active>span,
    .pagination>.active>span:focus,
    .pagination>.active>span:hover {
        background: $red;
        border-color: $red;
        color: #fff;
        font-weight: bold;
    }

    .top-area {
        background: $red;
        color: #fff;

        a {
            color: #fff;
        }
    }

    .search {
        background: $red;
    }

    .style-button {
        background: $red;
        color: #fff;
        border: 4px solid #EA5C71;
    }

    .ribbon {
        background: $red;
        color: #fff;
    }
}

.blue {

    .pagination>.active>a,
    .pagination>.active>a:focus,
    .pagination>.active>a:hover,
    .pagination>.active>span,
    .pagination>.active>span:focus,
    .pagination>.active>span:hover {
        background: $lightblue;
        border-color: $lightblue;
        color: #fff;
        font-weight: bold;
    }

    .top-area {
        background: $lightblue;
        color: #fff;

        a {
            color: #fff;
        }

    }

    .search {
        background: $lightblue;
    }

    .style-button {
        background: $lightblue;
        color: #fff;
        border: 4px solid #9AC9E9;
    }

    .ribbon {
        background: $lightblue;
        color: #fff;
    }

    .search,
    .pdf {
        &:hover {
            background-color: $lightblue;
        }
    }
}

.green {

    .pagination>.active>a,
    .pagination>.active>a:focus,
    .pagination>.active>a:hover,
    .pagination>.active>span,
    .pagination>.active>span:focus,
    .pagination>.active>span:hover {
        background: $green;
        border-color: $green;
        color: #fff;
        font-weight: bold;
    }

    .top-area {
        background: $green;
        color: #fff;

        a {
            color: #fff;
        }

    }

    .search {
        background: $green;
    }

    .style-button {
        background: $green;
        color: #fff;
        border: 4px solid #5DC8C4;
    }

    .ribbon {
        background: $green;
        color: #fff;
    }

    .search,
    .pdf {
        &:hover {
            background-color: $green;
        }
    }
}

.new-homes-area {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 50px;

    .new-home {
        padding: 50px 0;
        margin: 0 30px;

        @media(max-width: 767px) {
            margin: 0;
        }

        border-top: 1px solid #ccc;

        &:first-child {
            border-top: 1px solid transparent;
        }

        h2 {
            font-size: 19px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .image {
            background-size: cover;
            background-position: 50% 50%;
            height: 220px;
            border: 5px solid #ccc;
            margin-bottom: 15px;



        }
    }

    .style-button {
        &:hover {
            background: #333;
            border-color: #999;
            text-decoration: none;
        }
    }

}

.survey-area {
    padding-top: 25px;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;

    a {
        color: #666;

        &:hover {
            text-decoration: none;
        }
    }

    .style-button {
        &:hover {
            background: #333;
            border-color: #999;
        }
    }
}

.single-survey {
    border: 1px solid #CCCCCC;

    margin-bottom: 30px;
}


.survey-heading {
    font-weight: bold;
    font-size: 21px;
    color: #fff;
    padding: 20px 15px;
    background-color: #292323;

 
    transition: background-color 0.5s ease-out;
}

.survey-img img {
    width: 100%;
}

.survey-info {
    padding: 30px 15px 10px 15px;
}

.auction-page-area {
    font-size: 15px;
    padding-top: 30px;
    padding-bottom: 50px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .download-brochure {
        margin-top: 0;
        margin-bottom: 30px;
        display: block;
    }
}

.auction-intro {
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.auction-intro-inside {
    padding: 25px;

    a {
        color: #D51532
    }
}



.auction-hover-content {
    background-color: #F1F1F1;
}


.auction-links-area {
    border: 1px solid #ccc;
    margin-bottom: 30px;

    transition: background-color 0.5s ease-out;

    &:hover {
        background-color: #F1F1F1;

        .auction-heading {
            background-color: #D51532;
        }
    }
}

.auction-heading {
    padding: 20px;
    background-color: #333333;
    color: #fff;

    h4 {
        font-family: inherit;
        font-weight: bold;
        font-size: 18px;
    }
    transition: background-color 0.5s ease-out;
}

.auction-hover-title {
    background-color: #D51532;

}

.auction-links {
    padding: 13px;

    li {
        margin-bottom: 3px;
    }

    i {
        width: 36px;
        text-align: center;
        color: #D51532;
        margin-right: 5px;
        font-size: 24px;
    }

    a {
        color: #666;

        &:hover {
            color: #D51532;
        }
    }
}

.property-title {

    h1 {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 26px;
        font-weight: normal;
    }



}


.red .download-brochure {
    background: $red;
    border: 4px solid #f88e9e;

    &:before {
        background-image: url('/image/pdf-red.png');
    }
}

.blue .download-brochure {

    background: $lightblue;
    border: 4px solid #9AC9E9;

    &:before {
        background-image: url('/image/pdf-blue2.png');
    }

}

.green .download-brochure {

    background: $green;
    border: 4px solid #5DC8C4;

    &:before {
        background-image: url('/image/pdf-green.png');
    }
}



.download-brochure {

    display: inline-block;
    padding: 0px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding-left: 55px;
    min-width: 200px;
    line-height: 35px;
    position: relative;
    margin-top: 20px;

    &:before {
        content: '';
        position: absolute;
        height: 35px;
        width: 35px;
        background-size: auto;
        background-position: -5px -5px;
        background-color: #fff;
        top: 0;
        left: 0;
        transition: all 0.5s ease-out;
    }

    &:hover,
    &:focus {
        background: #333;
        border-color: #999;
        text-decoration: none;
        color: #fff;

    }

}

.property-big-image {
    border: 4px solid #ccc;
}

.property-slider {
    margin-bottom: 2px;
    border: 4px solid #ccc;

    .item {

        img {
            width: 100%;
        }
    }


}

.property-thumb-slider {
    padding-left: 30px;
    padding-right: 30px;

    .item {
        margin: 3px;
        opacity: 1;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;

        img {
            width: 100%;
        }
    }

    .owl-item {

        &:hover,
        &:focus {
            .item {
                opacity: 0.8;
            }

        }
    }

    .owl-item.active {
        .item {
            opacity: 0.8;

        }

    }

    .owl-buttons {

        position: inherit;
        margin-top: 0;

        .owl-prev,
        .owl-next {
            position: absolute;
            height: calc(100% - 6px);
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px;
            background: #999;
            background-image: none;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;



        }

        .owl-prev {
            top: 3px;
            left: 0;

            &:before {
                content: '\f104';
                font-family: FontAwesome;
                position: absolute;
                height: 100%;
                width: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 37px;
                text-indent: 0px;


            }

        }

        .owl-next {
            top: 3px;
            right: 0;

            &:before {
                content: '\f105';
                font-family: FontAwesome;
                position: absolute;
                height: 100%;
                width: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 37px;
                text-indent: 0px;


            }
        }

    }


}


.red .property-thumb-slider .owl-prev,
.red .property-thumb-slider .owl-next {

    &:hover {
        background: $red;
    }
}

.blue .property-thumb-slider .owl-prev,
.blue .property-thumb-slider .owl-next {

    &:hover {
        background: $lightblue;
    }
}

.green .property-thumb-slider .owl-prev,
.green .property-thumb-slider .owl-next {

    &:hover {
        background: $green
    }
}

.property-detail-area {
    padding: 55px 0 110px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;

    .property-info {
        padding: 70px 0;

        h3.description {
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 19px;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 20px;

        li {
            padding-left: 16px;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 5px;
                width: 6px;
                height: 11px;
            }
        }
    }

}

.red .property-detail-area ul li:before {

    background: $red;
}

.blue .property-detail-area ul li:before {

    background: $lightblue;
}

.green .property-detail-area ul li:before {

    background: $green
}