.instagram-heading{
    text-align: center;
    margin: 30px 0;
    .section-title{
        color: $blue;
        font-size: 20px;
        @media(min-width: 768px){
            font-size: 25px;
        }
        @media(min-width: 992px){
            font-size: 30px;
        }
        @media(min-width: 1200px){
            font-size: 35px;
        }
    }
    .iconify{
        color: $red;
        font-size: 30px;
                
                @media(min-width: 768px){
                    font-size: 35px;
                }
                @media(min-width: 992px){
                    font-size: 40px;
                }
    }
}

.instafeed-container {
    clear: both;
    background: #fff;
    overflow-x: auto;
    scrollbar-width: none;
    /* Firefox 64 */
    -ms-overflow-style: none;

    /* Internet Explorer 11 */
    &::-webkit-scrollbar {
        /** WebKit */
        display: none;
    }

}

#instafeed {

    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    padding: 0;
    display: flex;
    width: 1500px;
    scrollbar-width: none;
    /* Firefox 64 */
    -ms-overflow-style: none;

    /* Internet Explorer 11 */
    &::-webkit-scrollbar {
        /** WebKit */
        display: none;
    }

    @media screen and (min-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
    }

    .instafeed-item {
        position: relative;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        margin: 0;
        white-space: normal;
        scroll-snap-align: center;
        color: #fff;
        text-decoration: none;
        background: transparent;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;

        }

        img,
        .instafeed-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            max-width: 100%;
            z-index: 3
        }

        .overlay {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 10%;
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            z-index: 4;
            transition: 0.2s all ease-in-out;
            font-size: 13px;

            .caption {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                margin-bottom: 10px;
                ;
            }

            .date {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
            }
        }

        &:hover {
            .overlay {
                opacity: 1;
            }
        }

        @media screen and (min-width: 0px) {
            flex-basis: 60%;
            max-width: 60%;

        }

        @media screen and (min-width: 600px) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @media screen and (min-width: 750px) {
            flex-basis: 40%;
            max-width: 40%;
        }

        @media screen and (min-width: 900px) {
            flex-basis: 32%;
            max-width: 32%;
        }

        @media screen and (min-width: 1000px) {

            display: block;
            width: auto;
            margin: 0;
            flex-basis: auto;
            max-width: 100%;
        }
    }
}