@import "fonts";
@import "owl-carousel";
@import "myvariables";
@import "core";
@import "home";
@import "property-pages";
@import "standard-pages";
@import "property-pages-responsive";

@import "layout/header/header";
@import "layout/header/menu";
@import "layout/pageHeader";
@import "layout/footer/footer";
@import "layout/footer/brands";

@import "modules/awards";
@import "modules/buttons";
@import "modules/textRows";
@import "modules/section";
@import "modules/featured";
@import "modules/footerSlider";
@import "modules/instafeed";
@import "modules/testimonials";
@import "modules/checkboxes";


body {
    font-family: 'Euclid Circular B';
    color: #666;
    font-size: 15px;


    @media(min-width: 768px) {
        font-size: 16px;
    }

    .breadcrumb-container,
    .breadcrumb {
        display: none;
    }


    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        .image {
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto !important;
                max-width: none;
            }
        }
    }
}