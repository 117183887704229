#header{

    padding: 20px 0;

    .logo{
        img{
            width: 200px;
            @media (min-width: 576px){
                width: 230px;
            }
            @media (min-width: 768px){
                width: 250px;
            }

        }
    }
    .menu-toggle{
        
        background: $red;
        width: 50px;
        height: 50px;
        flex-basis: 50px;
        cursor: pointer;
        //border-radius: 8px;
        .line{
            display: block;
            height: 3px;
            background: #fff;
            width: 30px;
            margin-bottom: 5px;
            opacity: 1;
            transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
            border-radius: 3px;
            &:last-child{
                margin-bottom:0;
            }
        }
        
        &.open{
            
            .line{
                
        
                &:nth-child(1){
                    transform: rotate(45deg) translate3d(3px,3px,0);
                    margin-bottom:0!important;
                    background: #fff;
                }
                &:nth-child(2){
                    opacity: 0;
                    visibility: hidden;
                    margin-bottom:0!important;
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate3d(1px,-2px,0);
                    margin-bottom:0!important;
                    background: #fff;
                }
            }
            background: $red;
            
            &:hover, &:hover{
                .line{
                    margin-bottom: 0;
                    
                }
                background: $red;
            }
            
        }
        
        &:hover, &:hover{
            
            background: $red;
        }
        
    }
    
    .menu-container{
        
        @media (max-width: 991.98px){
            z-index: 100;
            position: fixed;
            right: 0;
            top: 0;
            width: 250px;
            flex-basis: 250px;
            height: 100%;
            flex-basis: 100%;
            background: $lightgrey;
            overflow-y: auto;
            display: flex;
            
            justify-content: flex-start;
            transform: translateX(250px);
            // opacity: 0;
            // visibility: hidden;
            transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                transform: translateX(0);
                transition: 0s all cubic-bezier(0.075, 0.82, 0.165, 1);
                display: none;

                &.open{
                    transform: translateX(0);
                    display: block;
                }
           }
            
            .menu-close{
                position: fixed;
                z-index: 101;
                top: 15px;
                right: 15px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                //border: 1px solid #fff;
                .iconify{
                    font-size: 38px;
                    color: $blue;
                    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
                    &:hover, &:focus{
                        color: $red;
                    }
                }
            }
         }

         #main-menu{
    
            flex-basis: 100%;
            max-width: 100%;
            
            position: relative;
            z-index: 10;
            margin-bottom: 0;
        
            > div > ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                list-style: none;
                margin: 0; 
                padding: 0;
                @media (min-width: 992){
                    width: auto;
                }
                > li { 
                    
                    
                    position: relative;
        
                    @media (min-width:992px){
                        margin-left: 10px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
        
                 
                    > a {
                        color: $blue;
                        font-weight: 500;
                        font-size: 14px;
                        padding: 15px 20px;
                        display: block;
                        position: relative;
                        flex: 0 0 100%;
                        height: 50px;
                       
                        overflow: hidden;
                        border-bottom: 1px solid #e4e4e4;
                        border-left: 8px solid $lightgrey;
                        @media (min-width: 992px){
                            padding: 8px 15px;
                            font-size: 15px;
                            @media (min-width:769px) and (max-width:1200px) {
                                padding: 8px 8px;
                                font-size: 14px;
                            }
                            border-bottom: 0px solid #e4e4e4;
                            height: auto;
                            background: #f3f1f1;
                            letter-spacing: 1px;
                            color: #666;
                            
                            text-transform: uppercase;
                            border-left: 0px ;
                            min-width: 150px;
                            text-align: center;
                        }

                      

                        span{
                            position: relative;
                            z-index:2;
                        }
        
                        &:before {
                            content: '';
                            height: 100%;
                            border-radius: 0;
                            width: 100%;
                            display: block;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            z-index: 1;
                            transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
                            transform: translateY(0);
                            @media (max-width: 991.98px){
                                display: none;
                            }
                        }

                        &.red,&.lightblue,&.green,&.orange{
                            @media (max-width: 991.98px){
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            }
                        }

                        &.red{
                            @media (min-width: 992px){
                            border-bottom: 4px solid $red;
                            }
                            &:before {
                                background-color: $red;
                            }
                            @media (max-width: 991.98px){
                                
                                border-left: 8px solid $red;
                            }

                        }
                        &.lightblue{
                            @media (min-width: 992px){
                            border-bottom: 4px solid $lightblue;
                            }
                            &:before {
                                background-color: $lightblue;
                            }
                            @media (max-width: 991.98px){
                                
                                border-left: 8px solid $lightblue;
                            }
                        }
                        &.green{
                            @media (min-width: 992px){
                            border-bottom: 4px solid $green;
                            }
                            &:before {
                                background-color: $green;
                            }
                            @media (max-width: 991.98px){
                               
                                border-left: 8px solid $green;
                            }
                        }
                        &.orange{
                            @media (min-width: 992px){
                            border-bottom: 4px solid $orange;
                            }
                            &:before {
                                background-color: $orange;
                            }
                            @media (max-width: 991.98px){
                               
                                border-left: 8px solid $orange;
                            }
                        }
        
                        &:hover {
                            color: $red;
                            @media (min-width: 992px){
                                color: #fff;
                            
                                &:before {
                                    transform: translateY(-100%);
                                }

                                &.red{
                                    border-bottom: 4px solid darken($red, 10%);
                                    
                                }
                                &.lightblue{
                                    border-bottom: 4px solid darken($lightblue, 10%);
                                
                                }
                                &.green{
                                    border-bottom: 4px solid darken($green, 10%);
                                    
                                }
                                &.orange{
                                    border-bottom: 4px solid darken($orange, 10%);
                                
                                }
                            }
                        }
        
                    }

                }
            }
            
           
            
        }
    }
    
    
}
