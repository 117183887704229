.banner-area{
    padding: 25px 0;
    background-size: cover;
    background-position: 50% 100%;
    overflow: hidden;
    background-color: $blue;
    background-image: url('/image/inner-header-bg.jpg');
    @media(min-width: 576px){
        padding: 35px 0;
    }
    @media(min-width: 992px){
        padding: 45px 0;
    }
    h1,.h1{
        font-size: 30px;
        font-weight: bold;
        color: #fff;
        line-height: 1.3;
        
        margin: 0;

        @media(min-width: 576px){
            font-size: 35px;
            line-height: 1.1;
        }
        @media(min-width: 768px){
            font-size: 40px;
            line-height: 1;
        }
        @media(min-width: 992px){
            font-size: 50px;
        }
        @media(min-width: 1200px){
            font-size: 60px;
            
        }
    }   
    .left{
        flex-flow: 1;
        
        .sub-title{
            color: #fff;
            font-size: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            .iconify{
                color: #fff;
                font-size: 15px;
                margin-left: 15px;
            }
            @media(min-width: 576px){
                text-align: left;
                justify-content: flex-start;
            }
            @media(min-width: 768px){
                font-size: 25px;
            }
            @media(min-width: 992px){
                font-size: 30px;
            }
            @media(min-width: 1200px){
                font-size: 35px;
            }
        }
    }

    .image{
        border-radius: 50%;
        flex: 0 0 150px;
        max-width: 150px;
        height: 150px;
        border: 5px solid #fff;
        overflow: hidden;
        margin: 0 auto 20px;
        order: 1;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            
        }

        @media(min-width: 576px){
            margin: 0;
            flex: 0 0 250px;
            max-width: 250px;
            height: 250px;
            order: 2;
            border: 10px solid #fff;
        }
        @media(min-width: 768px){
            flex: 0 0 300px;
            max-width: 300px;
            height: 300px;
            margin: 0;
            margin-right: 20px;
        }
        @media(min-width: 992px){
            flex: 0 0 350px;
            max-width: 350px;
            height: 350px;
            margin-right: 50px;
            border: 15px solid #fff;
        }
        @media(min-width: 1200px){
            flex: 0 0 430px;
            max-width: 430px;
            height: 430px;
           
        }
    }

    &.home{

        .left{
            max-width: 80%;  
            margin: 0 auto;
            order: 2;
            text-align: center;
            @media(min-width: 576px){
                order: 1;
                margin-right: 20px;
                text-align: left;
            }
            @media(min-width: 768px){
                max-width: 50%;  
                margin-left: 20px;
            }
            @media(min-width: 992px){
                max-width: 40%;
                margin-left: 50px;
                margin-right: 0;

                
            }
            @media(min-width: 1200px){
                max-width: 37%;  
                margin-left: 50px;
            }
            h1, .h1{
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 13px;
                @media(min-width: 768px){
                    padding-bottom: 30px;
                    margin-bottom: 25px;
                }
                padding-left: 0;
                &:after{
                    content: '';
                    background: $blue;
                    height: 3px;
                    width: 100px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    @media(min-width: 768px){
                        height: 5px;
                        left: 0;
                        transform: translateX(0);
                    }
                }
            }
        }
    }
   
}