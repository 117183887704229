// main : ../stylesheet.scss
.button{
    cursor: pointer !important;
    &.full{
        padding: 10px 20px;
        min-width: 150px;
        display: inline-block;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 14px;
        @media(min-width: 768px){
            font-size: 15px;
        }
        text-align: center;
        overflow: hidden;
        background-size: 100% 200%;
        background-position: 100% 0%;
        transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
        &.red{
            background-image: linear-gradient(darken($red,10%) 0%, darken($red,10%) 50%, $red 50%, $red 100%);
            color: #fff;
        }
        &.blue{
            background-image: linear-gradient(darken($blue,10%) 0%, darken($blue,10%) 50%, $blue 50%, $blue 100%);
            color: #fff;
        }

        &.lightblue{
            background-image: linear-gradient(darken($lightblue,10%) 0%, darken($lightblue,10%) 50%, $lightblue 50%, $lightblue 100%);
            color: #fff;
        }

        &:hover, &:focus{
            background-position: 100% 100%;
        }
        
        
    }
}