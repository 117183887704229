.brand-area {
    padding-top: 40px;
    padding-bottom: 50px;
    @media only screen and (max-width: 767px) {
        padding-top: 15px;
        
        .owl-prev {
            background-image: url("/image/res-arrow-left-2.png")
        }

        .owl-next {
            background-image: url("/image/res-arrow-right-2.png")
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .owl-prev {
            background-image: url("/image/res-arrow-left-2.png")
        }

        .owl-next {
            background-image: url("/image/res-arrow-right-2.png")
        }
    }

    
}