*{
    outline: 0;
}



.owl-page{
	width: 16px;
	height: 16px;
	background-color: #333333;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	-o-border-radius: 20px;
	border-radius: 20px;
	display: inline-block;
	margin: 10px 5px;
	&.active{
		background-color: #D51532;
	}
}
.contact-area{
	padding-top: 25px;
	padding-bottom: 30px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
}
.single-location{
	border: 1px solid #ccc;
	margin-bottom: 20px;
	font-size: 15px;
        
        .info{
            padding: 40px;
            min-height: 230px;
            
        }
        
}
.address{
	  
        
	a{
		color: $red
	}
}
.google-maps {
    position: relative;
    height: 250px;
    bottom: 0;
    display: block;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.pdfs{
    margin-top: 30px;
    .pdf-file {
        padding-left: 40px;
        padding-top: 7px;
        color: #fff;
        font-weight: 700;
        line-height: 23px;
        background: $lightblue;
        height: 46px;
        border: 5px solid #9ac9e9;
        margin: 5px 0;
        text-align: center;
        cursor: pointer;
        
        .pdf{
                width: 39px;
                height: 36px;
                top: 10px;
                left: 15px;
                background: url("/image/pdf-blue.png") no-repeat 0 0 #fff;
        }
    }
    
    .pdf-file:hover{
        border-color: #bbb;
        background: #aaa;
        
    }
    
    
    
    
  
}


.page.blue{
    .sorting-property-area{
        padding-top: 30px;
    }
}


#valuation-modal{

    .modal-dialog {
        @media(min-width: 768px){
            width: 750px;
            margin: 10% auto 30px;
        }
        
    }
    
    .modal-content{
        padding: 50px 40px;
        border-radius: 0;
        h3{
            font-size: 32px;
            margin-top:0;
            color: #666;
            margin-bottom: 20px;
            display: inline-block;
            padding-bottom: 8px;
            border-bottom: 7px solid $red;
            font-weight: bold;
        }
        .text{
            font-size: 14px;
            line-height: 1.5;
            margin-bottom: 20px;
        }
        
        .style-button{
            background: $red;
            color: #fff;
            border: 4px solid #EA5C71;
            font-size: 15px;
            padding: 10px 20px;
        }
        
        .radio-buttons, .checkboxes{
            
            margin-bottom: 20px;
            span{
                width: 100px;
                margin-right: 10px;
                display: inline-block;
                
                @media(max-width:767px){
                    display: block;
                    width: 100%;
                    margin-bottom: 7px;
                }
            }
            
            @media(max-width:500px){
                label{
                    display: block;
                    margin-left: 0;
                    margin-bottom: 3px;
                }
            }
            
        }
        .radio-buttons{
            margin-top: 20px;
        }
        a{
            display: inline-block; 
            background: $red;
            margin-top: 5px;
            color: #fff;
            padding: 8px 15px;
            &:hover, &:focus{
                text-decoration: none;
                background: #171717;
                color: #fff;
            }
        }
        
        .form-control{
            border: 3px solid #ccc;
            border-radius: 0;
            box-shadow: none;
            outline: none;
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 25px;
            padding: 0;
            margin-top: 0;
            padding: 5px 8px;
            cursor: pointer;
            opacity: 1;
        }

        .error{
            display: none;
            margin-bottom: 10px;
            em{
                color: darkred;
                display: block;
            }
        }
        .success{
            h3{
                font-size: 25px;
                border: 0;
            }
        }
        
    }
}

.latest-news-area{
    padding: 55px 0 110px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;
    
    .archived{
        text-align: center;
        padding: 20px 0 0;
        a{
            display: inline-block; 
            padding: 8px 20px;
            background: $red;
            border: 4px solid #f88e9e;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            &:hover, &:focus{
                 background: #333;
                border-color: #999;
                text-decoration: none;
            }
        }
    }
    
    
    
    .col{
        display: flex;
        margin-bottom: 30px;
        flex-basis: 25%;
        max-width: 25%;
        .news-item{
            height: 100%;
            width: 100%;
            border: 8px solid #ccc;
            .image{  
                display: block;
            }
            .bottom-text{
                padding: 15px;
                padding-bottom: 30px;
                .title{
                    font-size: 16px;
                    font-weight: bold; 
                    padding-bottom: 18px;
                    border-bottom: 2px solid #ccc;
                    margin-bottom: 18px;
                }
                .date{
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 1.6
                }
                .lead{
                    font-size: 13px;
                    line-height: 1.6;
                }
                
                .more-info{
                    background: $red;
                    display: inline-block;
                    padding: 3px 8px;
                    color: #fff;
                    font-size: 13px;
                    &:hover, &:focus{
                        background: #333;
                        text-decoration: none;
                    }
                }
                
            }
            
        }
    }
    
    @media (max-width: 1200px){ 
        .col {
            flex-basis: 25%;
            max-width: 25%;
        }
    }
    @media (max-width: 992px){ 
        .col {
            flex-basis: 33.33333%;
            max-width: 33.33333%;
        }
    }
    @media (max-width: 767px){ 
        .col {
            flex-basis: 50%;
            max-width: 50%;
        }
    }
    @media (max-width: 450px){ 
        .col {
            flex-basis:100%;
            max-width: 100%;
        }
    }
    
}

.news-detail-area{
    padding: 55px 0 110px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;
    
    
    h1{
        font-size: 16px;
        margin-bottom: 20px;
        
    }
    .back-news{
        display: inline-block; 
        padding: 8px 20px;
        background: $red;
        border: 4px solid #f88e9e;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
        &:hover, &:focus{
            background: #333;
            border-color: #999;
            text-decoration: none;
        }
    }
    
    .main-image{
        max-width: 100%;
        border: 6px solid #ccc;
        margin-bottom: 20px;
    }
    
}