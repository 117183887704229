.section{
    overflow: hidden;
    &.blue{
        background: $blue;
    }

    .inner-padding{
        padding-left: 5%;
        padding-right: 5%;
    }

    &.margined{
        margin: 15px 0;
        @media(min-width: 576px){
            margin: 25px 0;
        }
    }
    .section-title{
        
        font-weight: 600;
        font-size: 25px;
        @media(min-width: 576px){
            font-size: 30px;
        }
        @media(min-width: 768px){
            font-size: 35px;
        }
        @media(min-width: 992px){
            font-size: 40px;
        }
        @media(min-width: 1200px){
            font-size: 45px;
            
        }
    }

    .subtitle{
        font-family: 'BlackJack'!important;
		font-weight: 400!important;
        color: #155467;
        font-size: 25px;
        padding-top: 15px;
        margin-top: 15px;
        position: relative;
        display: block;
        &:after{
            content: '';
            background: $red;
            height: 5px;
            width: 100px;
            position: absolute;
            top: 0;
            left: 50%;
                transform: translateX(-50%);
            @media(min-width: 576px){
                left: 0;
                transform: translateX(0);
            }
        }

        &.no-line{
            padding-top: 0;
            margin-top: 0;
            &:after{
                display: none;
            }
        }

        @media(min-width: 576px){
            text-align: left;
        }
     
        @media(min-width: 992px){
            font-size: 28px;
        }
        @media(min-width: 1200px){
            font-size: 32px;
        }
    }

    .role{
        font-size: 16px;
        font-weight: bold;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 10px;
        position: relative;
        color: $blue;
        margin-bottom: 10px;
        text-transform: uppercase;
        display: block;
        text-align: center;
        &:before{
            content: '';
            height: 4px;
            width: 40px;
            background-color: $red;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @media(min-width: 576px){
                top: 50%;
            left: 0;
            transform: translateY(-50%);
            }
        }
        
        @media(min-width: 576px){
            text-align: left;
            padding-left: 50px;
            padding-top: 0;
            margin-top: 0;
        }
        @media(min-width: 992px){
            font-size: 18px;
        }
        @media(min-width: 1200px){
            font-size: 21px;
        }
    }


    &.selling-with-us{

        .text-row{
           padding: 5%;
           
        }
        .text-row:nth-child(even){
            background: #f1f0ec;
        }
    }
  
}