.top-area{
	background: $red;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	
	@media (min-width: 992px){
		letter-spacing: 1px;
	}
	a{
		color: #fff;
		&:hover{
			color: rgba(255,255,255,0.6);
		}
	}

	.left{
		padding-left: 20px;
		ul{
			display: flex;
			align-items: stretch;
			li{
				margin: 0;
				padding: 0;
				a{
					color: #fff;
					background-size: 100% 200%;
					background-position: 100% 0%;
					transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
					background-image: linear-gradient(transparent 0%, transparent 50%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.5) 100%);
					padding: 12px 10px;
					margin: 0;
					display: block;
					text-align: center;
					&:hover, &:focus{
						background-position: 100% 100%;
					}
				}
			}
		}
	}

	.contact-info{
		padding: 12px 20px;
		flex-grow: 1;
		@media  (min-width:991px) and (max-width:1090px) {
			max-width: 35%;
		}
		
		@media (min-width: 992px){
			flex-grow: 0;
			background: rgba(255,255,255,0.3); 
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				flex-basis: 475px;
			}
		}
		ul{
			display: flex;
			align-items: center;
			justify-content: center;
			li{
				
				flex: 0 0 50%;
				max-width: 50%;
				text-align: center;
				@media (min-width: 992px){
					max-width: none;
					margin: 0 5px;
				}
				a{
					display: inline-flex;
					align-items: center;
					margin-left: 5px;
					.iconify{
						font-size: 18px;
						margin-right: 5px;
					}
					@media (min-width: 992px){
						letter-spacing: 1px;
					}
					@media (min-width:1200px){
						letter-spacing: 3px;
					}
				}
			}
		}
	}

	
}