/*		Default Layout: 1200px and above. 
		Gutters: 24px.
		Outer margins: 48px.
		Leftover space for scrollbars @1200px: 32px.
-------------------------------------------------------------------------------
cols    1     2      3      4      5      6      7      8      9      10
px      68    160    252    344    436    528    620    712    804    896    */













/*		Tablet Layout: 1024px.
		Gutters: 24px.
		Outer margins: 28px.
		Inherits styles from: Desktop Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (min-width: 992px) and (max-width: 1199px) {

	.col-lg-offset-1{margin-left:8.333333333333332%}
	.col-lg-offset-2{margin-left:16.666666666666664%}
	
}



/*		Tablet Layout: 768px.
		Gutters: 24px.
		Outer margins: 28px.
		Inherits styles from: Default Layout.
-----------------------------------------------------------------
cols    1     2      3      4      5      6      7      8
px      68    160    252    344    436    528    620    712    */

@media only screen and (min-width: 768px) and (max-width: 991px) {
.sorting-property-img{
	img{
		width: 100%;
	}
}
.auction-intro{
	img{
		margin: 25px 0 0 25px;
	}
}
.auction-links{
	li{
		width: 100%;
		display: inline-block;
	}
}

.col-md-offset-1{margin-left:8.333333333333332%}
	
	
	
	
}


/*		Mobile Layout: 320px.
		Gutters: 24px.
		Outer margins: 34px.
		Inherits styles from: Default Layout.
---------------------------------------------
cols    1     2      3
px      68    160    252    */

@media only screen and (max-width: 767px) {

.sorting-property-img{
	img{
		width: 100%;
	}
}

.auction-links{
	li{
		width: 100%;
	}
}
.address{
	img{
		margin-bottom: 20px;
	}
}
	
	
}




/*		Wide Mobile Layout: 480px.
		Gutters: 24px.
		Outer margins: 22px.
		Inherits styles from: Default Layout, Mobile Layout.
------------------------------------------------------------
cols    1     2      3      4      5
px      68    160    252    344    436    */

@media only screen and (min-width: 480px) and (max-width: 767px) {

	
	
	
	

}
