#footer-slider{
    background-color: #d2cdc7;
    .item{
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
        .image{
            flex: 0 0 100%;
            max-width: 100%;
            overflow: hidden;
           
            position: relative;
            z-index: 1;

            @media(min-width: 768px){
                flex: 0 0 50%;
                max-width: 50%;
            }
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
                
            }
        }
        .content{
            flex: 0 0  100%;
            max-width: 100%;
            padding:  5% 30px; 
            text-align: center;
            @media(min-width: 768px){
                flex: 0 0 50%;
                max-width: 50%;
                text-align: left;
            }
            .section-title{
                color: $blue;
            }
            .iconify{
                color: $red;
                margin: 5px 0;
                font-size: 30px;
                
                @media(min-width: 768px){
                    font-size: 35px;
                }
                @media(min-width: 992px){
                    font-size: 40px;
                }
            }
        }
    }

}