$color-green: $blue; 
.checkbox {
   
   margin: 10px 10px;
   margin-top: 10px!important;
   overflow: hidden;
    label {
        position: relative;
       
        display: inline-flex;
        align-items: center;
        padding-left: 35px;
        line-height: 31px;
        margin-bottom: 0;
        font-weight: 600;
        cursor: pointer;
        
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin: 4px;
            width: 22px;
            background: #fff;
            height: 22px;
            transition: transform 0.28s ease;
            border-radius: 0px;
            border: 4px solid #ccc;
        }
        &:after {
            content: '';
            display: block;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $color-green;
            border-left: 2px solid $color-green;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.25s;
            position: absolute;
            top: 12px;
            left: 10px;
        }
    }
    input[type="checkbox"] {
        width: auto;
        opacity: 0.00000001;
        position: absolute;
        left: 0;
        margin-left: -20px;
        &:checked ~ label{
            &:before {
                border: 2px solid $color-green;
            }
            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }
        &:focus + label::before {
            outline: 0;
        }
    }
}