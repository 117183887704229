#featured-property{
    .bg-image{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 0;
        width: auto;
		max-width: none;
    }
    .content{
        padding: 5%;
        color: #fff; 
        position: relative;
        z-index: 1;
        order: 2;
        @media(min-width: 768px){
            
            order: 1;
        }

        .inner{
            position: relative;
            z-index: 1;
        }
      
        .section-title{
            
            color: #fff;
        }

        
        .iconify{
            color: $red;
            margin: 5px 0;
            font-size: 30px;
                
                @media(min-width: 768px){
                    font-size: 35px;
                }
                @media(min-width: 992px){
                    font-size: 40px;
                }
        }

        .address{
            font-size: 17px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .text{
            font-size: 14px;
            line-height: 1.6;
        }

        .price{
            font-weight: bold;
            font-size: 22px;
            margin: 20px 0;
            display: block;
        }   

        .sorting-featured_property-price{
            li{
                margin-bottom: 10px;;
                font-size: 14px;
                img{
                    margin-right: 5px;;
                    width: 35px;
                }
            }
          
        }

        .button{
            margin-top: 20px;
        }
    }

    .image{
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        overflow: hidden;
        background-color: darken($blue,5%);
        position: relative;
        z-index: 1;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
            
        }
        @media(min-width: 768px){
            flex: 0 0 60%;
            max-width: 50%;
            order: 2;
        }
        @media(min-width: 992px){
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

}