.bg-graphics{
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    left: 0;
    top: 0;
    .triangle{
        position: absolute;
        max-width: 50%;
        &.top{
            top: 0;
            left: 0;
            &.right{
                left: auto;
                right: 0;
            }
        }
        &.bottom{
            bottom: 0;
            right: 0;
            &.left{
                right: auto;
                left: 0;
            }
        }
        
        
    }
}

.banners{
    @media  (max-width: 991px){
        flex-wrap: wrap;
    }
    .banner{
        flex-basis: 50%;
        max-width: 50%;
        @media  (max-width: 991px){
            flex-basis: 100%;
            max-width: 100%;
        }
        .inner{
            padding: 60px;
            color: #fff;
            @media  (max-width: 991px){
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
                max-width: 750px;
            }
            @media  (max-width: 767px){
                max-width: 540px;
                padding-top: 40px;
                padding-bottom: 40px;
            }
            h2{
                margin-top: 0;
                font-size: 38px;
                @media  (max-width: 767px){
                    font-size: 35px;
                }
                @media  (max-width: 575px){
                    font-size: 30px;
                }
            }
            .text{
                margin-top: 20px;
                margin-bottom: 10px;
                max-width: 68%;
              
                @media  (max-width: 575px){
                    max-width: 100%;
                    font-size: 15px;
                }
            }

            .buttons{
                @media  (max-width: 767px){
                    margin-top: 10px;  
                }
                @media  (max-width: 400px){
                    margin-top: 20px;  
                }
                .style-button{
                    @media  (max-width: 400px){
                        display: flex;
                        margin: 10px 0!important;
                        justify-content: center;
                      
                    }
                }
            }
        }

        &.left{
            background-color: #e51937;
            background-image: url('/image/banner1.png');
            background-position: right bottom;
            background-size: auto 100%;
            background-repeat: no-repeat;
            .style-button{
                text-decoration: none;
                background: #b21129;
                border: 4px solid #fff;
                color: #fff;
                cursor: pointer;
                &:hover, &:focus{
                    background: #333;
                    border-color: #999;
                }
            }
        }
        &.right{
            background-color: #2a2a2a;
            background-image: url('/image/banner2.png');
            background-position: right top;
            background-size: auto auto;
            background-repeat: no-repeat;
            h3{
                font-size: 24px;
                margin-top: 20px;
            }
            .style-button{
                text-decoration: none;
                background: #b21129;
                border: 4px solid #fff;
                color: #fff;
                cursor: pointer;
                &:hover, &:focus{
                    background: #333;
                    border-color: #999;
                }

                &.black{
                    background: #000;
                    margin-left: 10px;
                }
            }
        }
    }
}




.transparent-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #E51937;
    opacity: 0.4
}


.brand-slider {
    padding: 0;
    margin: 0;


    .bnw {
        display: block;

        img {
            -webkit-filter: grayscale(1);
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: gray;
        }

        &:hover {
            img {
                -webkit-filter: grayscale(0);
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
                filter: none !important;
            }

        }
    }
}



.brand-logo {
    li.brand-logo-image {
        margin-top: 5px;
        background-repeat: no-repeat;
        background-position: center 0px;
        padding: 11px 7px 0 20px;
        height: 70px;

        &:hover {
            background-position: center -70px;
        }

    }
}

.owl-buttons {
    position: relative;
    margin-top: 30px;
}

.owl-prev,
.owl-next {
    width: 46%;
    height: 50px;
    display: inline-block;
    text-indent: -9999px
}

.owl-prev {
    background: url("/image/res-arrow-left.png") no-repeat right top;
    margin-right: 2%;
}

.owl-next {
    background: url("/image/res-arrow-right.png") no-repeat left top;
    margin-left: 2%;
}