.footer-area {
	background: $blue;
	color: #fff;
	font-size: 13px;
	line-height: 21px;
	padding: 7% 0 5%;

	h3 {
		color: #fff;
		margin-bottom: 30px;
		font-weight: 600;
		font-size: 25px;
	}

	a {
		color: #fff;

		&:hover,
		&:focus {
			color: #999999;


		}

		;
	}

	.footer-logo{
		display: inline-block;
		margin-top: 30px;
		@media(min-width:768px){
			margin-top: 0;
		}
	}


	.bg-image {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 100%;
		z-index: 0;
		width: auto;
		max-width: none;
	}

	.copyright-area {
		margin-top: 20px;
		position: relative;
		z-index: 1;
		@media(min-width:768px){
			text-align: center;
			margin-top: 40px;
		}
		p {
			color: #999999;
		}

		a {
			color: #999999;

			&:hover,
			&:focus {
				color: #fff;


			}

			;
		}
	}

	.social {
		margin: 30px 0;
		h3{
			margin: 0;
			margin-bottom: 10px;
		}
		li {
			padding: 2px;
		}

		a {
			display: inline-block;
			width: 40px;
			height: 40px;
			line-height: 40px;
			color: #fff;
			background: #7d959d;
			text-align: center;
			font-size: 20px;
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			-ms-border-radius: 50px;
			-o-border-radius: 50px;
			border-radius: 50px;
		}

		a:hover {
			background: $red;
		}
	}

	.footer-menu {
		display: block;
		margin:  15px 0;

		@media(min-width: 768px){
			display: inline-block;
			margin: 0 15px;
		}
		> a {
			padding: 5px 0;
			display: block;
			@media(min-width: 768px){
				margin: 5px 0;
				padding: 0 10px;
				border-right: 1px solid ;
				line-height: 1;
				display: inline-block;
				&:last-child{
					border-right: 0;
				}
			}
			@media(min-width: 992px){
				margin: 0;
				&:last-child{
					border-right: 1px solid ;
				}
				
			}
		
		}
	}
	


}