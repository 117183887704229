.position-relative {
    position: relative;
}

.team-heading {
    text-align: center;
    margin: 30px 0;

    .section-title {
        color: $blue;
        font-size: 35px;
        font-size: 20px;

        @media(min-width: 768px) {
            font-size: 25px;
        }

        @media(min-width: 992px) {
            font-size: 30px;
        }

        @media(min-width: 1200px) {
            font-size: 35px;
        }
    }

    .iconify {
        color: $red;
        font-size: 30px;

        @media(min-width: 768px) {
            font-size: 35px;
        }

        @media(min-width: 992px) {
            font-size: 40px;
        }
    }
}



.text-row {

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10% 0%;
    position: relative;
    flex-wrap: wrap;
    z-index: 1;

    @media(min-width: 576px) {
        flex-wrap: nowrap;
        padding: 5% 0%;
    }

    @media(min-width: 768px) {
        padding: 3% 0%;
    }

    .image {
        border-radius: 50%;
        flex: 0 0 150px;
        max-width: 150px;
        height: 150px;
        border: 5px solid #fff;
        overflow: hidden;
        margin: 0 auto 20px;
        order: 1;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;

        }

        @media(min-width: 576px) {
            margin: 0;
            flex: 0 0 250px;
            max-width: 250px;
            height: 250px;
            order: 2;
            border: 10px solid #fff;
            margin: 0;
            margin-left: 20px;

        }

        @media(min-width: 768px) {
            flex: 0 0 300px;
            max-width: 300px;
            height: 300px;

        }

        @media(min-width: 992px) {
            flex: 0 0 370px;
            max-width: 370px;
            height: 370px;
            border: 15px solid #fff;
            margin: 0;
            margin-left: 50px;
        }

        @media(min-width: 1200px){
            flex: 0 0 430px;
            max-width: 430px;
            height: 430px;
           
        }
    }

    &:nth-child(even) {
        flex-direction: row-reverse;

        .image {


            margin: 0 auto 20px;

            @media(min-width: 576px) {
                margin: 0;
                margin-right: 20px;
            }

            @media(min-width: 992px) {
                margin: 0;
                margin-right: 50px;
            }
        }
    }

    .text {

        flex: 0 1 100%;
        max-width: 100%;
        margin: 0 auto;
        order: 2;
        text-align: center;

        @media(min-width: 576px) {
            order: 1;
            text-align: left;
            max-width: 52%;
            flex: 0 1 52%;
            margin: 0;
        }


        .section-title {

            color: $blue;

        }

        .iconify {
            color: $red;
            font-size: 25px;

            @media(min-width: 768px) {
                font-size: 30px;
            }

            @media(min-width: 1200px) {
                font-size: 40px;
            }
        }

        .content {
            font-size: 15px;
            margin-bottom: 30px;

            @media(min-width: 768px) {
                font-size: 16px;
            }

            @media(min-width: 1200px) {
                font-size: 17px;
            }

            &.smaller {
                font-size: 14px;

                @media(min-width: 768px) {
                    font-size: 15px;
                }

                @media(min-width: 992px) {
                    font-size: 16px;
                }
            }
        }
    }


    &.team {
        align-items: flex-start;

        .text {
            max-width: 100%;
            flex: 0 1 100%;

            @media(min-width: 768px) {
                max-width: 70%;
                flex: 0 1 70%;

            }

            .section-title {
                font-size: 25px;

                text-align: center;

                @media(min-width: 576px) {
                    text-align: left;
                }

                @media(min-width: 992px) {
                    font-size: 30px;
                }

                @media(min-width: 1200px) {
                    font-size: 35px;
                }
            }
        }

        .image {

            height: 150px;
            flex: 0 0 150px;
            max-width: 150px;
            border: 5px solid #d2cdc7;

            @media(min-width: 576px) {
                height: 200px;
                flex: 0 0 200px;
                max-width: 200px;
            }

            @media(min-width: 992px) {
                height: 250px;
                flex: 0 0 250px;
                max-width: 250px;
            }
        }
    }

}