.awards-area{
    padding: 30px 0 15px 0;
    min-height: 120px;
    //background: #252525;
    color: #333;
    font-size: 15px;
    text-align: center;
    @media(min-width: 768px){
        font-size: 17px;
    }
    .awards{
        display: flex;
        justify-content: center;

        .item:first-of-type{
            border:5px solid #eee;
            margin:0 15px 0 0;
        }
        .item:last-of-type{
            border:5px solid #eee;
            margin:0 0 0 15px;
        }
        @media(max-width: 575px) {
            flex-direction: column;
            .item:first-of-type {
                border: 5px solid #eee;
                margin: 0 0 15px 0;
            }

            .item:last-of-type {
                border: 5px solid #eee;
                margin: 0;
            }
            .right{
                flex:100%;
            }
        }
    }


    .left, .middle, .right{
        padding:15px;

        
    }

    .middle{
        ul{
            li{
                
                padding: 0; 
                margin: 0;
                padding-right: 8px;
                margin-right: 6px;
                border-right: 2px solid #fff;
                line-height: 1;
                &:last-child{
                    border-right: 0;
                    padding-right: 0;
                    margin-right: 0;
                }
            }
        }

        .gold-winner{
            padding: 10px 20px;
            background: #d6bf0d;
            font-size: 15px;
            line-height: 1;
            font-weight: bold;
            margin-top: 10px;;
            @media(min-width: 768px){
                font-size: 19px;
            }
        }
    }
}