#testimonials{

    padding: 5% 0;

   
    @media(min-width: 768px){
        column-count: 2;
        column-gap:30px;
    }

    .testimonial{
        background: #f4f4f4;
        padding: 30px;
        margin-bottom: 30px;
        break-inside: avoid;

        .content{
            margin-bottom: 10px;
            font-style: italic;
        }

        strong{
            display: block;
        }
    }

}